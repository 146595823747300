import * as React from 'react';
import cls from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { open } from 'src/explore/compositions/Cart/actions';
import { useRoutes } from 'src/explore/hooks/useRoutes';

import styles from './index.module.sass';

export const CartButton = ({ className }: { className?: string }) => {
  const dispatch = useDispatch();
  const routes = useRoutes();

  const cart = useSelector(( state: any ) => state.data.cart.data );
  const count = cart?.number_of_items || 0;

  const handleClick = ( e: React.MouseEvent ) => {
    e.preventDefault();

    dispatch( open());
  };

  return (
    <a
      className={cls( className, styles.cart, 'd-flex text-decoration-none spec__cart_button' )}
      href={routes.checkout.root()}
      onClick={handleClick}
    >
      <FontAwesomeIcon className="align-self-start" icon={faCartShopping} />

      {count > 0 && <span className="spec__cart-total-count ml-1 fs-4">{`(${count})`}</span>}
    </a>
  );
};
