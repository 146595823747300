const height = () => {
  const header: HTMLElement = document.querySelector( '.js__header' );
  const navigation: HTMLElement = document.querySelector( '.js__header-navigation' );

  if ( header && navigation ) {
    const navigationParentStyle = getComputedStyle( navigation.parentElement );

    // add link bar if always visible (desktop)
    if ( navigationParentStyle.position === 'static' ) {
      return header.offsetHeight + navigation.offsetHeight;
    }
    return header.offsetHeight;
  }
  return 0;
};

export const getSiteHeader = {
  height,
};
