import * as React from 'react';
import cls from 'classnames';

import styles from './index.module.sass';

interface Props {
  className?: {
    container?: string;
    button?: string;
  };
  isActive: boolean;
  onClick: () => void;
}

export const NavToggle: React.FC<Props> = ({ className, isActive, onClick }) => (
  <div className={className?.container}>
    <button
      className={cls(
        styles.button,
        'position-relative border-0 bg-transparent',
        className?.button,
        {
          [styles.active]: isActive,
        }
      )}
      type="button"
      onClick={onClick}
      aria-label="Toggle Navigation Popout"
    >
      <span className={`${styles.line} position-absolute`} />
    </button>
  </div>
);
