import React from 'react';

import { getSiteHeader } from 'src/explore/services/getSiteHeader';

const easeOutCubic = ( t ) => ( t - 1 ) ** 3 + 1;

export const ScrollLink = ({ children, className = '', target, targetOffset, ...props }) => {
  const handleClick = ( e ) => {
    e.preventDefault();

    // set scroll target to position
    let scrollTo = target;
    if ( typeof target !== 'number' ) {
      // set scroll target to element
      if ( document.querySelector( target )) {
        scrollTo = document.querySelector( target ).getBoundingClientRect().top + window.pageYOffset;
      }
      // set scroll target to numerical position in string if available or 0
      else {
        scrollTo = isNaN( Number( target )) ? 0 : Number( target );
      }
    }

    // offset (for sticky headers, etc)
    if ( targetOffset === 'header--explore' ) {
      scrollTo -= getSiteHeader.height();
    } else if ( typeof targetOffset === 'string' ) {
      scrollTo -= document.querySelector( targetOffset ).offsetHeight;
    }

    // if the scroll target is too close to the bottom of the page to be scrolled to the top of the window, adjust the scroll target to the maximum scroll value
    if ( scrollTo > document.documentElement.scrollHeight - window.innerHeight ) {
      scrollTo = document.documentElement.scrollHeight - window.innerHeight;
    }

    // calculate scroll distance
    const startPosition = window.pageYOffset;
    const distance = scrollTo - startPosition;

    // animate scroll
    const duration = 200;
    let start = null;
    const animate = ( timestamp ) => {
      if ( !start ) {
        start = timestamp;
      }
      const progress = timestamp - start;

      // step
      if ( progress < duration ) {
        window.scrollTo(
          0,
          Math.floor( startPosition + easeOutCubic( progress / duration ) * distance )
        );

        requestAnimationFrame( animate );
      }

      // finish
      else {
        window.scrollTo( 0, scrollTo );
      }
    };
    requestAnimationFrame( animate );
  };

  return (
    <a
      className={className}
      href={String( target ).indexOf( '#' ) === 0 ? target : ''}
      onClick={handleClick}
      {...props}
    >
      {children}
    </a>
  );
};
